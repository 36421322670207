import React,{ useState, useEffect, Fragment }  from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route,Link,NavLink, Switch } from 'react-router-dom';
import Sidebar from './Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getBgImageLogo } from './services/LoginServices';

function Home() {
    const [companyName,setCompanyName] = useState("Litvik Software Labs Pvt. Ltd.");
	useEffect(() => {
        getBgImageLogo(cookies)
        .then (
            response => {
                const companies = response.company_list.filter(obj => obj.status)
				console.log(JSON.stringify(companies)+"companies");
				const company = companies[0];
				if (company){
					console.log(JSON.stringify(company)+"company");
					setCompanyName(company.name);
				}
            }
        )
    }, []);
            const [cookies] = useCookies(['myToken']);
            const [category] = useCookies(['myCategory']);
            const [superuser] = useCookies(['mySuperuser']);
            const [data,setData]=useState([])
            return (
            <>
                   <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
                    <div id="page-wrap">
                        <h1 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>Welcome to {companyName} <br/>
                       
                        </h1>
                    </div>
                    </>
        );
    };
  
  export default Home;